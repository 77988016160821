.Counter_body{
    background-color: #ffffff;
    box-shadow: 0px 2px 8px 2px #b2b2b266;
    border-radius: 12px;
    display: inline-block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 545px;
    padding: 35px;
}
.Counter_body p{
    font-size: 24px;
    color: #002776;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 40px;
    position: relative;
}
.close_pop{
    width: 9px;
    height: 9px;
    background-image: url(../../../assets/images/close_black.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}
.of_wrp{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.of_wrp section{
    width: 31%;
}
.ask_button{
    font-size: 12px !important;
    text-align: center;
    cursor: pointer;
    background-color: #002776 !important;
    color: white !important;
}
.offer_strike{
    text-decoration: line-through;
}
.prc_out{
    color: #002776;
    font-family: 'Poppins-Medium';
    background-color: #0027762B;
    font-size: 16px;
    height: 35px;
    line-height: 35px;
    margin-top: 9px;
    border-radius: 3px;
    padding: 0px 10px;
    border: 1px solid #e4e9f8;
}
section input{
    width: 100%;
    height: 35px;
    margin: 0;
    margin-top: 9px;
    border-radius: 3px;
    padding: 0px 10px;
    border: 1px solid #d1d1d1;
    outline: none;
}
.mod_bt{
    display: flex !important;
    justify-content: center !important; 
}
.mod_bt .conBt{
    margin: 60px 10px 0px 10px !important;
}