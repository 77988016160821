.Button {
    /* background-color: transparent; */
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    /* font-weight: bold; */
}
.login{
    background-color: #002776;
    margin: 10px 0px;
    width: 100%;
    border-radius: 4px;
}
.login:hover{
    /* box-shadow: -3px 36px 39px #00277645; */
    background: #007681;
}

.Success {
    color: #5C9210;
}

.Danger {
    color: #944317;
}