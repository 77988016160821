@font-face {
  font-family: 'Poppins-Regular';
  src: url(./assets/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url(./assets/fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: 'Poppins-Medium';
  src: url(./assets/fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: 'Poppins-Light';
  src: url(./assets/fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
}
html{
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
  font-size: 14px;
  font-family: 'Poppins-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
main,#root{
  height: 100%;
}
div,form,section,input{
  box-sizing: border-box;
}
img{
  width: 100%;
}
input{
  outline: none;
}
p{
  line-height: 100%;
}
.flx{
    display: flex;
    justify-content: space-between;
}
.in_pg{
  width: 200px;
  height: 200px;
  margin: 0 auto;
  margin-top: 100px;
  background-image: url(./assets/images/interface.svg);
  background-size: cover;
  background-position: center;
}
.back_link{
  color: #002776;
  font-family: 'Poppins-Light';
  margin-top: 20px;
}
.Logout{
  position: fixed;
  right: 80px;
  top: 34px;
  font-weight: 600;
  color: #8cc6ec;
  cursor: pointer;
}
.body_wrap{
  height: 100%;
  padding-left: 320px;
  padding-top: 100px;
  box-sizing: border-box;
  padding-right: 60px;
  padding-bottom: 20px;
}
.rel_div{
  position: relative;
}
.cmn_head{
 height: 70px;
}
.cmn_head p{
  color: #002776;
    font-size: 28px;
    margin-bottom: 2px;
    margin-top: 0px;
    line-height: 100%;
}
.cmn_head label{
  color: #58637A;
  font-size: 16px;
}
.toasts-container{
  box-shadow: 0px 0px 0px 0px #00000026 !important;
  z-index: 999;
  bottom: 20px !important;
}
.toast-error{
  border: 1px solid #ff7575;
  border-left: 4px solid #ff7575 !important;
  background-color: white !important;
  margin-bottom: 0px !important;
  border-radius: 0px !important;
  color: #f29c9c !important;
  padding: 10px 37px !important;
}
.toast-success{
  border: 1px solid green;
  border-left: 4px solid green !important;
  background-color: white !important;
  margin-bottom: 0px !important;
  border-radius: 0px !important;
  color: green !important;
  padding: 10px 37px !important;
}
.cont_wp{
  height: 100%;
  overflow: hidden;
}
.cont_wp .scrl_out{
  height: calc(100% - 70px);
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 30px;
}
.Back_link{
  color: #58637A;
  font-size: 16px;
  margin-top: 0px;
  position: relative;
  padding-left: 20px;
}
.Back_link::before{
  content: '<';
  width: 10px;
  height: 10px;
  left: 0;
  position: absolute;
}
.Cmn_in_head
{
  font-size: 24px;
  color: #404855;
  margin: 0px;
  margin-bottom: 10px;
}
.Cmn_in_head span{
  color:#58637A;
  font-size: 20px;
}
.Cmn_in_label{
    font-size: 14px;
    color: #404855;
    font-family: 'Poppins-Light';
}
.Cmn_in_label span{
  margin: 0px 10px;
}
  /* Scroll Bar */
  ::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background: #c3d2ff;
  }
  ::-webkit-scrollbar-thumb {
    background: #002776;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #002776;
  }
  .drop_arrow{
    position: absolute;
    width: 16px;
    height: 16px;
    box-shadow: 0px 3px 6px #00277647;
    background-color: #FFFFFF;
    border-radius: 50%;
    right: 10px;
    top: calc(50% - 8px);
    cursor: pointer;
    background-image: url(./assets/images/arrow-down-sign-to-navigate.png);
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
}
.addrDrop{
    position: absolute;
    width: 100%;
    background-color: white;
    top: 107%;
    left: 0;
    box-shadow: 0px 3px 6px #00277647;
    border-radius: 3px;
    z-index: 11;
    max-height: 120px;
    overflow: hidden;
    overflow-y: scroll;
}
.addrDrop p{
  margin: 0;
  padding: 10px 10px;
  cursor: pointer;
}


/* media_query_starts */
@media only screen and (max-width: 1680px) {
  .drop_arrow {
    width: 12px;
    height: 12px;
    top: calc(50% - 6px);
}
  .Cmn_in_label{
    font-size: 12px;
  }
  .Cmn_in_head{
    font-size: 20px;
  }
  .Cmn_in_head span{
    font-size: 16px;
  }
  .cmn_head p{
    font-size: 24px;
  }
  .cmn_head label{
    font-size: 12px;
  }
  .Back_link{
    font-size: 14px;
  }
}