.digiLoader{
  position: fixed;
  left: 0;
  top: 0;
  background-color:white;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
}
.loading-indicator .digiLoader{
  z-index: 9;
  opacity: 0.8;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.spinner {
  width: 40px;
  height: 40px;
  position: fixed;
  z-index: 99;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #3bbdf7b0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}