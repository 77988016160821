.pro_out{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}
span div:nth-child(even) .pro_out, span div:nth-child(even) .pro_out input{
    background-color: #d9d9d9;
    border: 0;
}
.pro_out div:nth-child(1) {
    width: 60%;
}
.pro_out div:nth-child(2) {
    width: 30%;
}
.pro_out div input {
    width: 100%;
    padding: 5px 5px;
    box-sizing: border-box;
    border: 0;
    background-color: transparent;
}
.modalWrap{
    width: 500px;
    padding: 25px;
    background-color: white;
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 1px 12px 3px #c2c2c24a;
    border-radius: 5px;
}
.modalWrap section{
    display: flex;
    justify-content: space-around;
}
section .i_o_wp{
    width: 48%;
}
section .io_wp input{
    width: 100%;
    background-color: red;
}