.OrderTableWrap table{
    width: 100%;
    border-collapse: collapse;
}
.OrderTableWrap table .table_head{
    color: #58637A;
    background-color: #E5EBF8;
    border-radius: 5px;
}
.OrderTableWrap table tbody tr:nth-child(even) {
    background-color: #F4F7FC;
}
tr th,tr td{
    padding: 10px 5px 10px 10px;
    text-align: left;
}
.OrderTableWrap table tbody tr{
    color:#7C7F84
}
.OrderfilterPanel{
    margin-bottom: 20px;
}
.datePickerButton{   
    width: 25px;
    height: 25px;
    background-color:#e1ecfe;
    border-radius: 4px;
    background-image: url(../../assets/images/calendar.svg);
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
}
.datePickerButton input{
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
}