.Input{
    width: 100%;
    /* padding: 10px; */
    box-sizing: border-box;
}
.Input input{
    width: 100%;
    height: 48px;
    margin-bottom: 20px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #002776A1;
    color:#002776 !important;
    border-radius: 5px;
    margin-top: 5px;
}
.Input label{
    color: #58637A;
}
@media only screen and (max-width: 1440px) {
    .Input input{
        height: 40px;
        font-size: 12px;
        margin-bottom: 15px;
    }
    .Input label{
        font-size: 12px;
    }
}