.modal_overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #ffffff91;
    z-index: 11;
    left: 0;
    top: 0;
    backdrop-filter: blur(5px);
}
.modal_body{
    background: #002776;
    box-shadow: 0px 47px 55px #00277666;
    border-radius: 12px;  
    display: inline-block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 545px;
}
.modal_body section{
    padding: 70px;  
    position: relative;
}
.mod_cls{
    position: absolute;
    right: 40px;
    top: 40px;
    width: 13px;
    height: 13px;
    background-image: url(../../../assets/images/close.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}
.modal_btn{
    width: 181px;
    height: 52px;
    text-align: center;
    line-height: 52px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color:#002776;
    margin: 0 auto;
    cursor: pointer;
    text-transform: capitalize;
}
.modal_body P{
    font-family: 'Poppins-light';
    font-size: 16px;
    text-align: center;
    color: white;
    padding-bottom: 40px;
    padding-top: 30px;
    line-height: 21px;
    margin: 0;
}
.pop_logo{
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border-radius: 50%;
    background-color: #7e94dc;
    background-size: 22px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
.hook{
    background-image: url(../../../assets/images/hook.png);
}
.alert{
    background-image: url(../../../assets/images/hazard.png);
}
@media only screen and (max-width: 1680px) {
    .modal_btn{
        height: 40px;
        font-size: 12px;
        line-height: 40px;
    }
    .modal_body P{
        font-size: 14px;
    }
}