.Modify_top{
    padding: 35px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px #0027761C;
    border-radius: 8px;
    
}
.Modify_top section{
    display: flex;
    justify-content: space-between;
}
.Modify_lab_wrap{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: 100%;
}
.mod_lab_out{
    position: relative;
}
.mod_lab{
    font-size: 20px;
    color: #002776;
    font-family: 'Poppins-Medium';
    background-color: #00ffff26;
    border-radius: 3px;
    height: 40px;
    padding: 0px 15px;
    line-height: 40px;
    /* min-width: 100px; */
    position: relative;
}
.mod_lab_out:nth-last-child(1) .mod_lab{
    background-color: transparent;
    padding: 0;
}
.sp_add span{
    font-family: 'Poppins-Light';
}
/* .mod_lab_out:nth-last-child(2) .mod_lab{
    font-size: 14px;
    padding-right: 40px;
} */
.mod_lab_out p{
    color:#404855;
    font-size: 14px;
}
.ReqBtnWrp{
    display: flex;
    justify-content: flex-end;
}
.ReqBtnWrp .conBt{
    padding: 0px 35px;
    margin-left: 13px;
    border: 0px solid;
    border-radius: 4px;
    cursor: pointer;
    color: #002776;
    background-color: #00ffff26;
    position: relative;
    height: 45px;
    line-height: 45px;
}
.ReqBtnWrp .conBt:nth-child(1){
    background-color: #EB4559;
    color: white;
}
.ReqBtnWrp .conBt:nth-child(1):hover{
    background-color: #EB4559;
    color: white;
}
.ReqBtnWrp .conBt:hover{
    background-color: #002776;
    box-shadow: -1px 0px 7px 2px #0027764f;
    color: white;
}
.disabOvl{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffffad;
    cursor: not-allowed;
}
.days_ico{
    width: 20px;
    height: 20px;
    background-image: url(../../../assets/images/transportation.png);
    margin: 2px;
    display: inline-block;
    vertical-align: middle;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin: 10px 0 5px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #002776;
}

input:focus + .slider {
  box-shadow: 0 0 1px #002776;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.controls{
    position: absolute;
    display: block;
    right: -15px;
    top: 5px;
}
.controls label{
    display: block;
    cursor: pointer;
    padding: 0 3px;
    line-height: 20px;
    text-align: center;
}
.controls label:hover{
    background: #f5f5f5;
}

@media only screen and (max-width: 1680px) {
    .mod_lab{
        font-size: 16px;
    }
    /* .mod_lab_out:nth-last-child(2) .mod_lab{
        font-size: 12px;
    } */
    .mod_lab_out p{
        font-size: 12px;
    }
}