.Base_wrap{
    display: flex;
    justify-content: space-between;
    background-color: #00FFFF0D;
    border-radius: 8px;
    border-top-left-radius: 0;
    width: 100%;
}
.Base_block{
    padding-bottom: 20px;
}
.Base_wrap .Base_block:nth-child(1){
    padding: 70px 35px;
    width: 40%;
}
.Base_wrap .Base_block:nth-child(2){
    width: 60%;
}
.Base_head{
    font-size: 24px;
    margin: 0px;
    margin-bottom: 27px;
}
.base_tog_btn{
    display: flex;
}
.base_tog_btn div{
    padding: 20px 45px;
    color: #002776;
    position: relative;
    cursor: pointer;
}
.actBtnB{
    background-color: #00FFFF0D;
    font-family: 'Poppins-Medium';
}
.actBtnB::after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 62px 0 0 52px;
    border-color: transparent transparent transparent #00FFFF0D;
    top: 0;
    right: -51px;
}
.Nop{
    width: 100%;
    height: 200px;
    text-align: center;
    line-height: 200px;
}
.ed_out{
    padding: 10px;
    margin: 10% auto;
    background-color: #00FFFF26;
    color: #002776;
    border-radius: 3px;
    min-width: 160px;
    text-align: center;
}
.Base_total_block{
    display: inline-block;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    padding-right: 40px;
    margin-bottom: 30px;
    min-width: 125px;
}
.Base_total_block span{
    color: #002776;
    font-size: 20px;
    font-family: 'Poppins-SemiBold';
}
.Base_total_block label{
    color: #404855;
    font-family: 'Poppins-Light';
}
.Base_ord_wrap section{
    display: flex;
    /* justify-content: space-between; */
}
.Base_ord_head{
    padding: 15px;
    margin: 0;
    background-color: #00ffff26;
    font-size: 16px;
}
.Base_ord_head.hasVolume{
    background-color: #00ffff66;
    color: #000000;
}
.Base_days{
    padding: 10px 4%;
    padding-bottom: 17px;
    position: relative;
    margin-bottom: 15px;
    margin-top: 10px;
    flex: 1 1;
    width: 0;
    text-align: center;
}
.doc_icon{
    width: 50px;
    height: 50px;
    background: url(../../../assets/images/icon-flight-data-subtle.svg) no-repeat center;
    background-size: contain;
}
.doc_icon.active{
    background: url(../../../assets/images/icon-flight-data.svg) no-repeat center;
}
.doc_icon.hide{
    opacity: 0;
}
section .Base_days::after{
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    right: 0;
    top:0;
    background-color: #00000012;
}
section .Base_days:nth-last-child(1)::after{
    width: 0px;
}
.spn1{
    color: #58637A;
    font-size: 14px;
    font-family: 'Poppins-Light';
    margin-bottom: 6px;
}
.Base_days span:nth-last-child(1){
    color: #58637A;
    font-size: 16px;
    font-family: 'Poppins-Medium';
}
.Base_days::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    width: 98%;
    background-color: #FFD695;
}
.dayBf::before{
    background-color: #e4e9f8;
}

.Base_days.hide span{
    color: #1b1d20 !important;
}
.Base_days.hide::before{
    background-color: #ffffff00 !important;
}
.hide_ovl{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(244, 247, 252, 0.75);
    display: none;
}
.hide .hide_ovl{
    display: block;
}
.Base_tool_tip{
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0px 10px 22px #00277626;
    border-radius: 12px;
    right: 0;
    top: 106%;
    z-index: 4;
    padding: 10px 17px;
    min-width: 130px;
    display: none;
    font-size: 14px;
    color: #58637A;
}
.Base_tool_tip div:nth-child(1){
    font-family: 'Poppins-Light';
    opacity: 0.6;
}
.Base_tool_tip div:nth-child(2){
    font-family: 'Poppins-Light';
    opacity: 0.6;
}
.Base_tool_tip div:nth-child(3){
    font-family: 'Poppins-Medium';
    opacity: 0.9;
}
.Base_days:hover .Base_tool_tip{
    display: block;
}
.Base_days.hide:hover .Base_tool_tip{
    display: none;
}
.Base_block .Base_ord_wrap:nth-last-child(1) section .Base_days .Base_tool_tip{
    top: -107%;
    box-shadow: -1px -6px 22px #00277626;
}
.ship_addr_wrp{
    padding-right: 40px;
    position: relative;
    display: inline-block;
}
.Base_addr_block p:nth-child(1){
    color: #58637A;
    font-family: 'Poppins-Medium';
}
.ship_addr_wrp, .addrDrop p{
    color: #404855;
    opacity: 0.7;
}
.base_add_wrp{
    width: 100%;
}

.base_header{
    padding-left: 150px;
}
.note_header{
    padding-left: 90px;
}
.base_ord_table{
    display: flex;
    background-color: #00ffff26;
    align-items: center;
    padding: 10px 0 10px 15px;
    border-bottom: 1px solid #e6e6e6;
}
.base_ord_table > section{
    width: calc(100% - 90px);
    display: flex;
}
.Base_ord_weight{
    /* margin: 5px 0; */
    width: 90px;
}
.base_ord_table .Base_days{
    padding: 10px 4%;
    margin: 0;
    text-align: center;
}
.base_ord_table .Base_days::before, .base_ord_table .Base_days::after{
    opacity: 0;
}
.note_btn{
    width: 120px;
    text-align: center;
    margin-bottom: 20px;
}
.announce_wrap{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.announce_wrap p{
    margin-right: 10px;
}
.toggle_wrap input{
        display: none;
}
.toggle_wrap input + label{
        outline: 0;
    display: block;
    width: 30px;
    height: 15px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
}
.toggle_wrap input + label:after{
    border-radius: 50%;
    background: #fff;
    transition: all 0.2s ease;
    left: 0;
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}
.toggle_wrap input:checked + label{
        background: #007681;
}
.toggle_wrap input:checked + label:after {
    left: 50%;
}
/* media_query_starts */
@media only screen and (max-width: 1680px) {
    .Base_head {
        font-size: 20px;
    }
    .Base_total_block {
        padding: 15px;
    }
    .Base_total_block span {
        font-size: 18px;
    }
    .Base_total_block label {
        font-size: 12px;
    }
    .Base_ord_head{
        font-size: 14px;
    }
    .spn1{
        font-size: 11px;
    }
    .Base_days span:nth-last-child(1){
        font-size: 14px;
    }
    .Base_tool_tip{
        font-size: 12px;
    }
}