.Summary_out{
    padding: 12px 0px;
}

.Summary_row{
    display: flex;
}
.Summary_col{
    flex: 1 1;
    width: 0;
    padding: 10px 0px 10px 20px;
    border-right: 1px solid #00277621;
}
.header_row .Summary_col_day{
    background-color: #002776;
}
.Summary_col_day{
    background-color: #c3d2ff;
    width: 120px;
}
.footer_total{
    font-weight: bold;
}
.Summary_row{
    background-color: #0027762B;
}
.header_row{
    background-color: #002776;
    color: white;
    margin-bottom: 2px;
}
.disable{
    opacity: 0.3;
    cursor: not-allowed !important;
}
.note_p{
    font-size: 12px;
    margin-top: 0px;
    line-height: 16px;
    color: #002776;
    padding-left: 40px;
    position: relative;
}
.note_p span{
    font-weight: bold;
    position: absolute;
    left: 0;
}