.pr_block{
    background-color: white;
    box-shadow: 0px 0px 5px 0px #0000001f;
    width: 30%;
    margin: 0 auto;
    margin-bottom: 20px;
}
.pr_block p:nth-child(1){
    margin: 0;
    padding: 10px;
    background-color: black;
    color: white;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
}
.day_out{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}
.tot_out{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #e6e6e6;
}
.day_out div:nth-child(1){
    width: 60%;
}
.day_out div:nth-child(2){
    width: 30%;
}
.day_out div input{
    width: 100%;
    padding: 5px 5px;
    box-sizing: border-box;
}
.prdBtn button{
    width: 100%;
    padding: 10px;
    margin: 14px 0px 0px 0px;
    box-shadow: 0px;
    border: 0px;
    background-color: #aac8dc;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
}
.prdBtn1 button{
    width: 100%;
    padding: 10px;
    margin: 0px 0px 0px 0px;
    box-shadow: 0px;
    border: 0px;
    background-color: #aac8dc;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
}
.er{
    text-align: center;
    padding: 30px 20px;
}