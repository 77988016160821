.titleSection{
    display: flex;
    justify-content: space-between;
    margin: 30px 0 10px;
}
.btn{
    border-radius: 4px;
    font-size: 14px;
    padding: 8px 25px;
    cursor: pointer;
    background-color: #002776;
    box-shadow: -1px 0px 7px 2px #0027764f;
    color: white;
    transition: all ease 0.5s;
}
.btn:hover{
    background: #4b64b5;
}
.popupContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #00000066;
    z-index: 11;
}
.popupWrap{
    width: 500px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 1px #00000022;
}
.popHead{
    padding: 20px;
    text-align: center;
}
.popHead h3{
    color: #002776;
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 2px;
    margin-top: 0px;
    line-height: 100%;
}
.popBody{
    padding: 0 20px 20px;
}
.fieldWrap{
    margin: 15px 0;
}
.fieldWrap label{
    font-size: 14px;
    display: block;
}
.fieldWrap input{
    box-shadow: none;
    margin: 0;
}
.fieldWrap select{
    border-radius: 3px;
    padding: 10px 10px;
    border: 1px solid #d1d1d1;
    display:  block;
    width: 100%;
}
.popBody .btnWrap{
    padding: 20px 0;
}
.popBody .btn{
    width: 200px;
    text-align: center;
    margin: auto;
}
.checkWrap{
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
}
.checkbox{
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.checkbox input{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.item {
    display: inline-block;
  }
  
  .item + .item::before {
    display: inline-block;
    white-space: pre;
    content: ", ";
  }